import { useEffect } from "react";

import Request from "../../Requests";

import { Drawer, Form, Button, notification } from "antd";
import { createFieldsValue, createFormData } from "../../Utils";
import { FileChooser } from "../../components";

export default function ({ visible, onClose, selectedRow }) {
    const fields = {
        gdf: {
            type: "STRING",
            rules: [{ required: true, message: "Gdf is required!" }],
            isFile: true,
        },
    };

    const [form] = Form.useForm();

    const onFinish = (values) => {
        let method = "post";

        values = createFormData(values, fields);

        if (selectedRow) {
            method = "put";
            values.append("id", selectedRow.id);
        }

        Request.table_1gfdg[method](values)
            .then(() => {
                hideDrawer(true);
            })
            .catch(({ message }) => {
                notification.error({ message });
            });
    };

    const hideDrawer = (changed) => {
        onClose(changed);
        form.resetFields();
    };

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(createFieldsValue(selectedRow, fields));
        }
    }, [visible]);

    return (
        <Drawer
            title={selectedRow ? "Update" : "Add"}
            placement="right"
            onClose={() => hideDrawer(false)}
            open={visible}
            width={600}
        >
            <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
                <Form.Item label="Gdf" name="gdf" rules={fields.gdf.rules}>
                    <FileChooser />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {selectedRow ? "Update" : "Add"}
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}
