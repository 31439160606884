import { Route, Routes } from "react-router-dom";

import { WelcomePage, Table_1gfdgPage, Table_2Page, Table_3Page } from "./pages/index";

import Root from "./layout/Root";

import "antd/dist/antd.css";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<WelcomePage />} />
                <Route path="/dashboard" element={<Root />}>
                    <Route path="/dashboard/table_1gfdg" element={<Table_1gfdgPage />} />
                    <Route path="/dashboard/table_2" element={<Table_2Page />} />
                    <Route path="/dashboard/table_3" element={<Table_3Page />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
